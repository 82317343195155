
































import { Component, Vue } from 'vue-property-decorator';

import {
  RegulatorAccount,
} from '@/app/shared/utilities/static-types';
import { createRegulatorAction } from '@adminStores/shared/actions';
import FormComponent from '@/app/shared/components/FormComponent.vue';
import rules from '@/app/shared/validation-rules';
import { AdminRoutes } from '@/app/shared/utilities/routes-names';

@Component({
  components: {
    FormComponent,
  },
  data: (vm: any) => ({
    emailRules: [
      rules.required(vm.$i18n.t('field_required')),
      rules.emailFormat(vm.$i18n.t('invalid_email')),
    ],
    nameRules: [
      rules.required(vm.$i18n.t('field_required')),
    ],
    contactPhoneRules: [
      rules.saudiPhoneFormat(vm.$i18n.t('phone_not_saudi_valid')),
    ],
    contactEmailRules: [
      rules.emailFormat(vm.$i18n.t('invalid_email')),
    ],
  }),
})
export default class CreateStoreOwner extends Vue {
  public email = '';
  public name = '';
  public contactName = '';
  public contactNameRules = [];

  public contactPhone = '';

  public contactEmail = '';

  public isValidForm() {
    if (!this.name) {

      return;
    }
    if (!this.email) {
      return;
    }
    return true;
  }

  public async createRegulator() {
    if (this.isValidForm()) {
      const regulatorAccount: RegulatorAccount = {
        EMAIL: this.email,
        NAME: this.name,
        CONTACT_PERSON: {
          NAME: this.contactName,
          PHONE: this.contactPhone,
          EMAIL: this.contactEmail,
        },
      };
      await createRegulatorAction(regulatorAccount);
      this.$router.push(AdminRoutes.REGULATORS);
    } else {
      throw new Error(this.$t('invalid_form').toString());
    }
  }

}
